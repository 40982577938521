import React, { useState } from "react";
import Layout from "../components/layout";
import "/resources/app.scss";
import AppContext from "../components/AppContext";
import { Router,useRouter } from "next/router";
import { CookiesProvider, useCookies } from "react-cookie";
import { SSRProvider } from "@react-aria/ssr";
import { SWRConfig } from "swr";
import { SessionProvider, signIn, useSession } from "next-auth/react";
export default function MyApp({ Component, pageProps: { session, ...pageProps } }) {
  const router = useRouter();
  const [sidebarStatus, setSidebarStatus] = useState(false);
  const [totalAmounts, setAmounts] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [loginUser, setLoginUser] = useState(session);
  const themes = { sidebarStatus, setSidebarStatus, showLoader, setShowLoader,loginUser, setLoginUser,totalAmounts, setAmounts };
  // Use the layout defined at the page level, if available

  Router.events.on('routeChangeStart', () => {
    setShowLoader(true);
  });

  Router.events.on('', () => {
    setShowLoader(false);
  });

  Router.events.on('routeChangeComplete', () => {
    setShowLoader(false);
  });

  Router.events.on('routeChangeError', () => {
    setShowLoader(false);
  });

  const getLayout =
    Component.getLayout ||
    ((page) => {
      return <Layout key={router?.asPath}>{page}</Layout>;
    });

  return (
    <SWRConfig value={{
      // provider: localStorageProvider,
      onSuccess: function (response) {
        setShowLoader(!response);
      },

      onError: (error, key) => {
        setShowLoader(false);
        if (error.status !== 403 && error.status !== 404) {
          //helper.sweetalert.toast(error.response.data.message,'error')
        }
      }
    }}>
    <CookiesProvider>
        <SSRProvider>
          <SessionProvider session={session}>
            <AppContext.Provider value={themes}>
              {Component.auth ? (
                <Auth>
                  {getLayout(<Component {...pageProps} />)}
                </Auth>
              ) : (
                <>{getLayout(<Component {...pageProps} />)}</>
              )}
            </AppContext.Provider>
          </SessionProvider>
        </SSRProvider>
      </CookiesProvider>
    </SWRConfig>
  );
}



function Auth({ children }) {
  const { data: user, status } = useSession({ required: true })
  //console.log(user);
  const [cookies, setCookie, removeCookie] = useCookies(['delta_cookie']);
  if (status === "loading") {
    return <div className="loading"></div>
  }
  if (user?.user?.email !== cookies?.access_token) {
    signIn();
  }
  //return signIn();
  return children
}