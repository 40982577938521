import axiosInstance from "../lib/axiosInstance";

class Auth {
   async login(data=[]){
        return await axiosInstance.post("/auth/login", data);
    }

    async profile() {
        return (await axiosInstance.get("/auth/profile")).data;
    }
}

export default new Auth();