import axios from "axios";
const BASE_URL = process.env.NEXT_PUBLIC_API_URL;
const axiosInstance =
    axios.create({
        baseURL: BASE_URL,
        headers: { 'Content-Type': 'application/form-data' },
        transformRequest: function (data, headers) {
            let access_token = localStorage.getItem('access_token');
            if (access_token) {
                headers.Authorization = "Bearer " + access_token;
            }
            return data;
        },
        validateStatus: function (status) {
            if (status == 401) {
                window.location.assign("/login");
            }
            return status >= 200 && status <= 204;
        }
    });

export default axiosInstance; 