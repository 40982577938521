// import 'components/layout.js'

import Navbar from "./navbar";
import Footer from "./footer";
import Sidebar from "./sidebar";
import AppContext from './AppContext';
import { useContext, useState,useEffect } from "react";
import authModel from "../models/auth.model";
import { useSession } from "next-auth/react";

export default function Layout({children }) {
  let {data:user,status}=useSession();
  const themeContext = useContext(AppContext);
  useEffect(()=>{
      themeContext.setLoginUser(user?.user);
    
  },[user])
 
  return (<div>
        <Navbar />
            <div className="container-fluid">
                {children}
            </div>
        </div>)
}
