import Link from "next/link";
import { useContext } from "react";
import { Dropdown } from "react-bootstrap";
import AppContext from "./AppContext";

export default function Navbar({ children }) {
  const themeContext = useContext(AppContext);
  const logoutAction = async function () {
    await localStorage.removeItem("access_token");
    await localStorage.removeItem("user");
    window.location.assign("/login");
  }

  return (
    <>
      <nav className="navbar navbar-expand-lg navbar-expand navbar-light bg-light shadow px-2">
        <Link className="navbar-brand" href="/">
LaxmiCF 
        </Link>
        <div className="navbar-nav ms-auto">
        <Link href="/" key={`home`} className="nav-item nav-link">
                Home</Link>
        {(themeContext?.loginUser?.name=="admin") && 
        <>
        <Link href="/users" key={`users`} className="nav-item nav-link">
        Users</Link>
        </>}

        <Dropdown className="nav-item">
          <Dropdown.Toggle className="nav-item nav-link" variant="light" size="sm">
            <i className="bi bi-person-circle"></i>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            <Dropdown.Item key={'navbar-profile'} href="/profile"><i className="bi bi-person-circle" />{themeContext?.loginUser?.name} Profile</Dropdown.Item>
            <Dropdown.Divider />
            <Dropdown.Item key={'navbar-logout'} onClick={logoutAction}><i className='bi bi-box-arrow-left' /> Logout</Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
</div>
      </nav>
    </>
  );
}
